<template>
  <TargetKpiDivisiForm mode="Tambah" module="Target KPI"> </TargetKpiDivisiForm>
</template>

<script>
import TargetKpiDivisiForm from './form-target';

const TargetKpiDivisiAdd = {
  name: 'TargetKpiDivisiAdd',
  components: { TargetKpiDivisiForm },
};

export default TargetKpiDivisiAdd;
</script>
